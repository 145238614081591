import useJwt from "@/auth/jwt/useJwt";
const axios = useJwt.axiosIns;

export async function uploadImage(name, file) {
  const formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(`${process.env.VUE_APP_API_URL}/agent/upload/${name}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return res.data.imageUrl;
}

export async function getImages() {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/agent/getImages`);
  return res.data;
}

export async function getSeo() {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/agent/getSeo`);
  return res.data;
}

export async function updateSeo(seo) {
  console.log(seo);
  await axios.post(`${process.env.VUE_APP_API_URL}/agent/updateSeo`, { seo });
}

export async function getPage() {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/agent/getPage`);
  return res.data;
}

export async function updatePage(page) {
  await axios.post(`${process.env.VUE_APP_API_URL}/agent/updatePage`, { page });
}

export async function getTestimonial() {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/agent/getTestimonial`);
  return res.data;
}

export async function updateTestimonial(idx, testimonial) {
  const { review, name, description } = testimonial;
  await axios.post(`${process.env.VUE_APP_API_URL}/agent/updateTestimonial/${idx}`, {
    testimonial: {
      review,
      name,
      description,
    },
  });
}

export async function addCustomer(data) {
  const res = await axios.post(`${process.env.VUE_APP_API_URL}/agent/addCustomer`, data);
  return res.data;
}

export async function getCustomers(skip, take, sort, filter) {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/agent/getCustomers?skip=${skip}&take=${take}&sort=${JSON.stringify(sort)}&filter=${JSON.stringify(filter)}`);
  return res.data;
}

export async function getShareLink(cusId, a, whatsapp = false, linkOnly = false) {
  const res = await axios.post(`${process.env.VUE_APP_API_URL}/agent/getShareLink/${cusId}`, { a, whatsapp, linkOnly });
  return res.data;
}

export async function getProfile() {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/agent/getProfile`);
  return res.data;
}

export async function updateProfile(profile) {
  await axios.post(`${process.env.VUE_APP_API_URL}/agent/updateProfile`, { profile });
}

export async function getReferral() {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/agent/getReferrals`);
  return res.data;
}

export async function getSales() {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/agent/getSales`);
  return res.data;
}

export async function batchAddCustomer(file) {
  const formData = new FormData();
  formData.append("file", file);
  const res = await axios.post(`${process.env.VUE_APP_API_URL}/agent/batchAddCustomer`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
}

export async function getAgentDashboard() {
  const res = await axios.get(`${process.env.VUE_APP_API_URL}/agent/dashboard`);
  return res.data;
}

export async function addSettlement(settlement) {
  const res = await axios.post(`${process.env.VUE_APP_API_URL}/settlement`, { settlement });
  return res.data;
}
